/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import slugify from "slugify"
import ReactMarkdown from "react-markdown"
import site from "../util/site.json"
import { useForm, ValidationError } from '@formspree/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"

export const pageQuery = graphql`
  query ViewQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
        listView {
          alignCardShow
          alignCards {
            cardAlign
            alignCardDecription
            alignCardImage {
              childImageSharp {
                fluid(maxWidth: 2340, maxHeight: 1754, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                sizes {
                  src
                }
              }
            }
          }
        }
        cardsView {
          CardShow
          cards {
            alignCardImgCaption
            cardImages {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
                sizes {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

const imageRating = site.meta.imageRating

const viewPrefix = `/view/`

const Pagination = props => (
  <div sx={viewStyles.postPagination}>
    <ul>
      {props.previous && props.previous.frontmatter.template === "view-page" && (
        <li>
          <Link
            to={viewPrefix + slugify(`${props.previous.frontmatter.title}`)}
            rel="prev"
          >
            <p>
              <span className="icon">&larr;</span> Previous
            </p>
            <span className="page-title">
              {props.previous.frontmatter.title}
            </span>
          </Link>
        </li>
      )}
      {props.next && props.next.frontmatter.template === "view-page" && (
        <li>
          <Link
            to={viewPrefix + slugify(`${props.next.frontmatter.title}`)}
            rel="prev"
          >
            <p>
              Next <span className="icon">&rarr;</span>
            </p>
            <span className="page-title">{props.next.frontmatter.title}</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const view = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { previous, next } = pageContext

  const hasImage = frontmatter.featuredImage.childImageSharp.sizes.src.includes(
    "spacer.png"
  )
    ? false
    : true
  const viewImage = hasImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""
  const viewImg = (
    <Img
      width="640"
      height="360"
      fluid={viewImage}
      objectFit="cover"
      objectPosition="100% 100%"
      alt={frontmatter.title + " - Featured image"}
    />
  )

  // view Align Cards
  const Aligncard = frontmatter.listView.alignCards
    ? frontmatter.listView.alignCards.map((align, index) => {
        const hasImage = align.alignCardImage.childImageSharp.sizes.src.includes(
          "spacer.png"
        )
          ? false
          : true
        const alignImage = hasImage
          ? align.alignCardImage.childImageSharp.fluid
          : ""
        const info = (
          <div sx={viewStyles.viewContent}>
            {align.alignCardDecription ? (
              <ReactMarkdown source={align.alignCardDecription} />
            ) : (
              ""
            )}
          </div>
        )
        const alignImages = (
          <Img
            width="640"
            height="360"
            fluid={alignImage}
            alt=" - Align card image"
            sx={viewStyles.featuredImage}
          />
        )
        return (
          <div key={"align" + index}>
            {align.cardAlign === "left" ? (
              <div sx={viewStyles.viewCard}>
                {alignImages}
                {info}
              </div>
            ) : (
              ""
            )}
            {align.cardAlign === "right" ? (
              <div sx={viewStyles.viewCard}>
                <div sx={viewStyles.orderOne}>{info}</div>
                <div sx={viewStyles.orderTwo}>{alignImages}</div>
              </div>
            ) : (
              ""
            )}
            {align.cardAlign === "center" ? (
              <div sx={viewStyles.viewCardCenter}>
                {alignImages}
                <div className="viewContentCenter">{info}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        )
      })
    : ""

  // view Cards
  const cards = frontmatter.cardsView.cards
    ? frontmatter.cardsView.cards.map((info, index) => {
        const hasImage = info.cardImages.childImageSharp.sizes.src.includes(
          "spacer.png"
        )
          ? false
          : true
        const cardsImage = hasImage ? info.cardImages.childImageSharp.fluid : ""
        return (
          <div sx={viewStyles.viewCards} key={"cards" + index}>
            {info.cardImages === "../../../static/assets/spacer.png" ? (
              ""
            ) : (
              <Img
                width="640"
                height="360"
                fluid={cardsImage}
                alt=" - Card image"
                sx={{
                  borderRadius: "12px",
                  border: "1px solid",
                  borderColor: "global.borderColor",
                }}
              />
            )}
            {info.alignCardImgCaption ? (
              <p sx={viewStyles.alignCardImgCaption}>
                {info.alignCardImgCaption}
              </p>
            ) : (
              ""
            )}
          </div>
        )
      })
    : ""

  // tag's in View post
  const tagLabel = frontmatter.tags
    ? frontmatter.tags.map((tag, index) => {
        return (
          <div key={"tag" + index}>
            {tag === "none" ? (
              ""
            ) : (
              <Link
                sx={viewStyles.tagStyle}
                to={`/tagview/` + slugify(`${tag}`)}
              >
                {tag}
              </Link>
            )}
          </div>
        )
      })
    : ""

  let props = {
    previous,
    next,
  }


  function ContactFormGetInTouch() {
    const [state, handleSubmit] = useForm("mdoyzlgy");
    if (state.succeeded) {
      return <p> Thank you for contacting me, I will write to you soon </p>
    }
    return (
      <div sx={contactStyles.wrapper}>
        <form
          sx={contactStyles.ContactFormGetInTouch}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value={frontmatter.title} />

          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter your email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <input
            type="hidden"
            id="message"
            name="message"
            placeholder="Message"
            value="Conctac Me"
          ></input>
          <div sx={contactStyles.sendButtonGet}>
            <button
              sx={{
                variant: "variants.button.primary",
                borderRadius: "12px",
                fontWeight: "600",
                mt: 1,
                mb: 1,
              }}
              type="submit"
              disabled={state.submitting}
            >
              Get In Touch
              {/* <RiSendPlane2Line sx={{ ml: 2 }} /> */}
            </button>
          </div>
        </form>
      </div>
    )
  }



  const viewSlug = slugify(`${frontmatter.title}`)
  const Breadcrumb = () => (
    <div
      sx={{
        variant: "variants.breadcrumb",
      }}
    >
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        sx={viewStyles.containerOl}
      >
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <Link itemProp="item" to="/view">
            <span itemProp="name">view</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        <span sx={{ color: "global.textColor" }}>›</span>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <Link
            itemScope
            itemType="https://schema.org/WebPage"
            itemProp="item"
            itemID={"/view/" + viewSlug}
            to={"/view/" + viewSlug}
          >
            <span itemProp="name">{frontmatter.title}</span>
          </Link>
          <meta itemProp="position" content="2" />
        </li>
      </ol>
    </div>
  )
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        article={true}
      />
      <div sx={viewStyles.viewPageContainer}>
        <article sx={viewStyles.viewContainer}>
          <div>
            <header>
              <div sx={viewStyles.homeBanner}>
                <div sx={viewStyles.viewDescription}>
                  <h1 sx={viewStyles.title}>{frontmatter.title}</h1>
                  <p sx={viewStyles.description}>{frontmatter.description}</p>
                  <ContactFormGetInTouch />
                  <div sx={contactStyles.cta}>
                    {imageRating && (
                      <img
                      src={imageRating.slice(15)}
                      alt="Rating Stars"
                      sx={contactStyles.ratingImage}
                      width="200"
                      height="360"
                      />
                      )}
                    <p sx={contactStyles.starText}> 5 / 5</p>
                  </div>
                  <p sx={contactStyles.starMessage}> Based on order ratings </p>
                </div>
                <div sx={viewStyles.viewImage}>{viewImg}</div>
              </div>

            </header>
          </div>
          <div
            sx={viewStyles.viewBody}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {frontmatter.listView.alignCardShow === true ? "" : Aligncard}
          {frontmatter.cardsView.CardShow === true ? (
            ""
          ) : (
            <div sx={viewStyles.viewListCards}>{cards}</div>
          )}
          {tagLabel ? <div sx={viewStyles.tagsDiv}>{tagLabel}</div> : ""}
          {(previous || next) && <Pagination {...props} />}
        </article>
        <Breadcrumb />
      </div>
    </Layout>
  )
}

export default view

const viewStyles = {
  viewPageContainer: {
    variant: "variants.section",
  },
  viewContainer: {
    variant: "variants.container",
    minHeight: "70vh",
    color: "global.textColor",
  },
  viewBody: {
    variant: "variants.markdown",
    maxWidth: "100ch",
    m: "0 auto",
    mb: 7,
    p: {
      fontSize: [3, 3, 3, 4],
    },
    h1: {
      textAlign: "center"
    }
  },
  viewDate: {
    pt: 3,
    display: ["block", "block", "flex"],
    alignItems: "center",
    color: "global.mutedColor",
    justifyContent: "center",
  },
  viewImage: {
    maxWidth: "100%",
    border: "1px solid",
    mb: 6,
    borderColor: "global.borderColor",
    overflow: "hidden",
    borderRadius: "12px",
    ".gatsby-image-wrapper": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      width: "100%",
    },
  },

  // Align Cards
  viewCard: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr  1fr"],
    alignItems: "start",
    justifyContent: "center",
    gap: ["30px", "30px", "60px"],
    my: [2, 2, 7],
    ".gatsby-image-wrapper": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
  },
  orderOne: {
    order: ["2", "2", "1"],
  },
  orderTwo: {
    order: ["1", "1", "2"],
  },
  viewCardCenter: {
    ".gatsby-image-wrapper": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
    ".viewContentCenter": {
      textAlign: ["left"],
      maxWidth: ["100%", "80%", "60%", "50%"],
      padding: "30px 0",
    },
  },
  featuredImage: {
    maxWidth: "100%",
    width: "100%",
    display: "block",
    m: "0",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "global.borderColor",
  },
  viewContent: {
    display: "block",
    h2: {
      fontSize: 5,
      fontWeight: "800",
      color: "global.headingColor",
      fontFamily: "body",
      letterSpacing: 0,
    },
    p: {
      mt: 0,
      fontSize: [3, 3, 3, 4],
      pb: 3,
      lineHeight: "1.8",
      color: "global.textColor",
      fontWeight: "300",
    },
  },

  //Cards
  viewListCards: {
    display: "grid",
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"],
    gap: 7,
    py: 4,
  },
  alignCardImgCaption: {
    fontSize: 4,
    mb: 1,
    color: "global.mutedColor",
    fontWeight: "300",
  },
  postPagination: {
    variant: "variants.pagination",
  },
  tagStyle: {
    variant: "variants.tag",
  },
  tagsDiv: {
    display: ["block", "flex"],
    py: 7,
  },
  homeBanner: {
    py: [5, 5, 6, 7, "auto"],
    m: "0 auto",
    bg: "home.background",
    width: ["100%"],
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "1fr 1fr"],
  },
  title: {
    m: 0,
    mb: [3, 4],
    p: 0,
    color: "home.headingColor",
    letterSpacing: "1px",
    fontSize: [
      "32px !important",
      "38px !important",
      "48px !important",
      "48px !important",
      "48px !important",
    ],
  },
  description: {
    m: 0,
    lineHeight: "1.6",
    maxWidth: "75ch",
    color: "home.textColor",
    pb: [3, 4],
    fontSize: [4],
  },
}

const contactStyles = {
  contactPage: {
    variant: "variants.section",
    pb: 0,
    minHeight: "75vh",
  },
  contactBody: {
    variant: "variants.container",
  },
  contactContainer: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    gap: ["30px", "60px"],
    alignItems: "center",
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
  },
  infoAction: {
    display: "block",
    mt: 5,
  },
  wrapper: {
    maxWidth: "100%",
  },
  title: {
    m: 0,
    color: "global.headingColor",
    fontSize: [
      "32px !important",
      "48px !important",
      "48px !important",
      "64px !important",
    ],
  },
  description: {
    display: "inline-block",
    m: 0,
    fontSize: [3, 4],
    color: "global.mutedColor",
    fontWeight: "light",
  },
  ContactFormGetInTouch: {
    display: "flex", 
    alignItems: "center",
    alignContent: "center",
    input: {
      width: "70%",
      maxWidth: "70%",
      mb: 0,
      mt: 0,
      mr: 1,
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
  },
  sendButtonGet: {
    mt: 0,
  },
  contactForm: {
    p: {
      m: 0,
    },
    input: {
      width: "100%",
      maxWidth: "100%",
      mb: 5,
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
    textarea: {
      minHeight: "200px",
      fontFamily: "inherit",
      width: "100%",
      maxWidth: "100%",
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      display: "block",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
  },
  contactInformation: {
    bg: "contact.infoBgColor",
    borderRadius: "12px",
    border: "2px solid",
    borderColor: "contact.borderColor",
    p: 4,
    alignSelf: "normal",
  },
  contactDetail: {
    display: ["block", "flex", "flex", "flex"],
    alignItems: "top",
  },
  infoIcon: {
    fontSize: 6,
    color: "contact.textColor",
    mr: 3,
  },
  contactInfo: {
    fontSize: 3,
    m: 0,
    color: "contact.textColor",
  },
  map: {
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
    borderColor: "contact.borderColor",
    iframe: {
      mb: "-7px",
      border: "1px solid",
      borderRadius: "12px",
      overflow: "hidden",
    },
  },
  sendButton: {
    mt: 5,
  },
  button: {
    color: "button.primaryTextColor",
    bg: "button.primaryTextHoverColor",
    ":hover": {
      color: "button.primaryBgColor",
      bg: "button.primaryBgHoverColor",
    },
  },
  wrap: {
    position: "relative",
    textarea: {
      mb: 0,
    },
  },
  cta: {
    mt: "24px",
    mb: 0,
    ml: "24px",
    display: "flex",
    alignItems: "center"
  },
  starText: {
    display: "inline-block",
    fontWeight: "800",
    ml: "10px",
    fontSize: "28px",
    color: "#7f7f7f",
    mb: "0",
    mt: "0",
  },
  starMessage: {
    width: "100%",
    display: "block",
    mt: "0px",
    color: "#ababab",
    fontWeight: "500",
    fontSize: "16px",
    ml: "18px",
  },
  ratingImage: {
    width: "48%",
    height: "auto",
    maxWidth: "124px",
    display: "inline-block",
    mb: "0",
    mt: "0",
  },
}
